import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormComponent } from 'src/app/_shared/classes/form-component.handler';
import { MessageService } from 'src/app/_shared/components/message';
import { IUser } from 'src/app/_shared/interfaces/IClientAdmin.interfaces';
import { ClientService } from 'src/app/_shared/services/client/client.service';
import { ClientAdminService } from 'src/app/_shared/services/clientAdmin';
import { eMatchValidator } from 'src/app/_shared/validators/confirm-password.validator';
import { removeSpaces } from 'src/app/_shared/validators/whitespaces.validator';
import { AppRoutes } from 'src/app/app-routes';
import { environment } from 'src/environments/environment.dev';

@Component({
  selector: 'app-client-sign-up-confirm',
  templateUrl: './client-sign-up-confirm.component.html',
  styleUrls: ['./client-sign-up-confirm.component.scss'],
})
@UntilDestroy()
export class ClientSignUpConfirmComponent
  extends FormComponent
  implements OnInit {
  private user!: IUser;
  private token!: string;

  public passwordVisible = false;
  public confirmPasswordVisible = false;
  public loading = false;

  constructor(
    private _message: MessageService,
    private _clientAdminService: ClientAdminService,
    private _client: ClientService,
    private route: ActivatedRoute,
    private _fb: FormBuilder,
    private _router: Router,
  ) {
    super();
    this.route.params.subscribe((params) => {
      this.token = params['token'];
    });
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this._getRegistrationData(this.token);
  }

  private _getRegistrationData(token: string) {
    this._clientAdminService
      ._getClientAdminRegistration(token)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          this.user = response;
          this.updateForm();
        },
      });
  }

  private updateForm() {
    if (this.form && this.user) {
      this.form.patchValue({
        first_name: this.user?.first_name || '',
        last_name: this.user?.last_name || '',
        email: this.user?.email || '',
      });
    } else if (this.user) {
      this.createForm();
    }
  }

  protected createForm(): FormGroup<any> {
    return this._fb.group(
      {
        first_name: [
          this.user?.first_name || '',
          [Validators.required, Validators.maxLength(15), removeSpaces],
        ],
        last_name: [
          this.user?.last_name || '',
          [Validators.required, Validators.maxLength(15), , removeSpaces],
        ],
        email: [{ value: this.user?.email || '', disabled: true }],
        password: ['', [Validators.required]],
        confirm_password: ['', [Validators.required]],
        phone: [
          '',
          [
            Validators.required,
            Validators.maxLength(15),
            Validators.minLength(6),
            Validators.pattern(environment.phonePattern),
            removeSpaces,
          ],
        ],
      },
      { validator: eMatchValidator('password', 'confirm_password') },
    );
  }

  public submit() {
    this.loading = true;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (this.form.invalid) {
      this._validateForm();
      this.loading = false;
      return;
    }
    this._addClientAdmin(this.token);
  }

  private _addClientAdmin(token: string) {
    const formData = this._createFormData();

    this._clientAdminService
      .verifyClientAdminRegistration(formData, token)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          const email = this.form.get('email')?.value;
          localStorage.setItem('email', email);
          setTimeout(() => {
            this.loading = false;
            this._router.navigate([AppRoutes.AUTH]);
            this._message.emitSuccess(
              this._translate.instant('auth.messages.verified'),
            );
          }, 1000);
        },
        error: (error) => {
          this._message.emitError(
            this._translate.instant('httpErrors.somethingWrongError'),
          );
          this.loading = false;
        },
      });
  }

  get confirmError() {
    if (
      this.form.get('confirm_password')?.invalid &&
      this.form.get('confirm_password')?.touched
    ) {
      return 'error';
    }
    return '';
  }
  get passwordError() {
    if (
      this.form.get('password')?.invalid &&
      this.form.get('password')?.touched
    ) {
      return 'error';
    }
    return '';
  }
}
