import {
  Component,
  inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ExportPoolingService } from '../../services';
import { IExportStatusResponse } from '../../interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { MessageService } from '../message';
import { TranslateService } from '@ngx-translate/core';
import { BackgroundService } from './background.service';

@UntilDestroy()
@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit {
  @ViewChild('exportMsgTml') exportMsgTml!: TemplateRef<{}>;

  protected _translate = inject(TranslateService);
  private _notification = inject(NzNotificationService);
  private _exportStatus = inject(ExportPoolingService);
  private _backgroundService = inject(BackgroundService);
  private _message = inject(MessageService);

  private statuses = {
    export: 'export_status',
  };

  ngOnInit(): void {
    const export_status: IExportStatusResponse | undefined =
      localStorage.getItem(this.statuses.export)
        ? JSON.parse(localStorage.getItem(this.statuses.export)!)
        : undefined;

    if (export_status?.status === 'inprogress') {
      setTimeout(() => {
        this._backgroundService.setExportStatus(export_status);
      }, 500);
    }

    this._backgroundService.onActiveExport$
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value) => {
          if (value) {
            this.checkExportStatus(value._id);
          }
        },
      });
  }

  public checkExportStatus(id: string) {
    const exportMsgId = this._notification.template(this.exportMsgTml, {
      nzDuration: 0,
      nzData: this._translate.instant('msg.info.export_in_progress'),
      nzStyle: {
        top: '75px',
        width: '225px',
        borderRadius: '5px'
      }
    }).messageId;

    this._exportStatus
      .checkExportStatus(id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (response) => {
          if (response.status === 'finished' || response.status === 'failed') {
            localStorage.removeItem('export_status');
            this._backgroundService.setExportStatus(null);
            this._notification.remove(exportMsgId);

            if (response.status === 'finished' && response.url) {
              this._message.emitSuccess(this._translate.instant('msg.success.export_data'));

              const link = document.createElement('a');
              link.href = response.url;
              link.download = response.filename + '';
              link.click();
            } else {
              this._message.emitError(this._translate.instant('msg.failed.export_data'));
            }
          }
        },
        error: () => {
          localStorage.removeItem('export_status');
          this._backgroundService.setExportStatus(null);
          this._notification.remove(exportMsgId);
          this._message.emitError(this._translate.instant('msg.failed.export_data'));
        }
      });
  }
}
