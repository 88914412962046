import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from '../footer/footer.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { FormsModule } from '@angular/forms';
import { LayoutComponent } from './layout.component';
import { ProfilePreviewModule } from '../_shared/components/profile-preview';
import { TranslateModule } from '@ngx-translate/core';
import { BackgroundModule } from '../_shared/components';

@NgModule({
  declarations: [HeaderComponent, LayoutComponent],
  imports: [
    CommonModule,
    RouterModule,
    FooterComponent,
    TranslateModule,
    ProfilePreviewModule,
    NzSelectModule,
    FormsModule,
    BackgroundModule
  ],
})
export class LayoutModule {}
