import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.dev';
import { IExportStatusResponse } from '../../interfaces';
import { interval, Observable, switchMap, takeWhile } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExportPoolingService {
  private _http = inject(HttpClient);

  private pollingInterval = 10000;

  public checkExportStatus(id: string): Observable<IExportStatusResponse> {
    return interval(this.pollingInterval).pipe(
      switchMap(() =>
        this._http.get<IExportStatusResponse>(
          `${environment.host}lifts/export/${id}`,
        ),
      ),
      takeWhile((response) => response.status === 'inprogress', true), // Continue polling until status is "finished"
    );
  }
}
