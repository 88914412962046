import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IExportStatusResponse } from '../../interfaces';

@Injectable({
  providedIn: 'root'
})
export class BackgroundService {
  private _onActiveExport = new BehaviorSubject<IExportStatusResponse | null>(null);
  public onActiveExport$ = this._onActiveExport.asObservable();

  public exportStatus: 'inprogress' | 'finished' = 'finished';

  public setExportStatus(value: IExportStatusResponse | null) {
    this._onActiveExport.next(value);
  }
}
